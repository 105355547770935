<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.own-info.header') }}</h1>
    </div>
    <div class="tile is-ancestor">
        
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.own-info.user.header') }}</p>
          <div class="columns">
            <div class="column is-half">
              <b-field :label="$t('message.own-info.user.firstname')">
                  <b-input v-model="user.first_name"></b-input>
              </b-field>

              <b-field :label="$t('message.own-info.user.lastname')">
                  <b-input v-model="user.last_name"></b-input>
              </b-field>

              <b-field :label="$t('message.own-info.user.company')" v-if="user.type == 'company'">
                  <b-input value="" disabled v-model="company.name"></b-input>
              </b-field>

              <b-field :label="$t('message.own-info.user.partner')" v-if="user.type == 'education_partner'">
                  <b-input value="" disabled v-model="partner.name"></b-input>
              </b-field>

              <b-button
                :loading="false"
                :label="$t('message.own-info.user.save')"
                size="is-large"
                class="fullwidth"
                type="is-success" 
                v-on:click="updateUserInfo" />
            </div>
            <div class="column is-half">

              <b-field :label="$t('message.own-info.user.email')">
                  <b-input type="email"
                      value=""
                      disabled 
                      v-model="user.email">
                  </b-input>
              </b-field>

              <b-field :label="$t('message.own-info.user.phone')">
                  <b-input v-model="user.mobile"></b-input>
              </b-field>

            </div>

          </div>
          <div class="columns">
              <div class="column is-half">
            <p class="title">{{ $t('message.own-info.password.header') }}</p>
            <p>{{ $t('message.own-info.password.txt') }}</p>
            <b-button
                :loading="false"
                :label="$t('message.own-info.password.reset')"
                size="is-large"
                class="fullwidth"
                type="is-success" 
                v-on:click="updatePassword" />
              </div>
            </div>

          <div class="columns" v-if="user.type == 'company'">
            <div class="column is-half">
              <p class="title">{{ $t('message.own-info.company.header') }}</p>
              <p>{{ $t("message.own-info.company.txt") }}</p>
              <b-button
                  :loading="false"
                  :label="$t('message.own-info.company.button')"
                  size="is-large"
                  class="fullwidth"
                  type="is-success" 
                  v-on:click="companyDetails" />
            </div>
          </div>

          <div class="columns" v-if="user.type == 'education_partner'">
            <div class="column is-half">
              <p class="title">{{ $t('message.own-info.education_partner.header') }}</p>
              <p>{{ $t("message.own-info.education_partner.txt") }}</p>
              <b-button
                  :loading="false"
                  :label="$t('message.own-info.education_partner.button')"
                  size="is-large"
                  class="fullwidth"
                  type="is-success" 
                  v-on:click="educationPartnerDetails" />
            </div>
          </div>
        </article>
      </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
            errors: {
                is_error: false,
                first_name: false,
                last_name: false,
                email: false,
                mobile: false,
                password: false,
                c_password: false,
            },
        }
    },
    methods: {
        updateUserInfo() {
            console.log("update user info")
            this.$store.dispatch("editUser", this.user).then((response) => {
              var status = response.status
              if (status >= 200 && status < 300) {
                  this.toastSuccess(this.$t('message.own-info.toaster.editSuccess'));
              } else {
                  this.toastFailure(this.$t('message.own-info.toaster.editError'));
              }
          }).catch((error) => {
              this.ERROR(error);
              this.toastFailure(this.$t('message.own-info.toaster.editError'));
          });
        }, 
        updatePassword() {
            console.log("update passeword")
        },
        companyDetails() {
            this.$router.push("/yritys")
        },
        educationPartnerDetails() {
            this.$router.push("/kumppani")
        }
    },
    computed: {
        user() {
            return this.$store.getters['getUser']
        },
        companies() {
            return this.$store.getters['getCompanies']
        }, 
        company() {
          return this.companies.find(comp => this.user.company_id == comp.id); 
        }, 
        partners() {
          return this.$store.getters['getPartners']
        },
        partner() {
          return this.partners.find(comp => this.user.education_partner_id == comp.id); 
        }, 
    },
    mounted() {
        this.$store.dispatch("fetchPartners");
        this.$store.dispatch("fetchCompanies");
        this.$store.dispatch("fetchUsers");
    }
}
</script>